<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="space-around">
      <v-col cols="12" sm="12" md="4">
        <v-row>
          <v-col align="center">
            <h2>Caja de Acción Social - Informe</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input v-model="form.nomina" accept=".txt" label="Archivo a procesar"></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-btn elevation="2" color="primary" :disabled="loading" :loading="loading" @click="upload">Aceptar</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        form: {
          nomina: undefined
        }
      }
    },

    methods: {
      async upload() {
        if (!this.form.nomina) return this.$toast.push("info", "Por favor seleccione un archivo de nomina");
        this.loading = true;
        await this.$api.sendForm("CAS", this.form);
        this.loading = false;
      }
    }
  }
</script>